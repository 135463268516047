import type { RoadRoutingFerryPreferenceEnum, SeaRoutingAreaEnum } from '../../../services/graphql/generated';
import {
  CalculatorFreightFuelTypeEnum,
  EmissionFreightCargoTypeEnum,
  EmissionFreightDistanceUnitEnum,
  EmissionFreightVehicleEmissionStandardEnum,
  EmissionFreightWeightUnitEnum,
} from '../../../services/graphql/generated';
import { CalculatorLocationTypeEnum, type CalculatorParentState } from '../types';

export const example2State = (
  autoTransshipment?: boolean,
  ferryPreference?: RoadRoutingFerryPreferenceEnum,
  seaRestrictedAreas?: SeaRoutingAreaEnum[],
): CalculatorParentState => ({
  cargoDetail: {
    cargo_type: EmissionFreightCargoTypeEnum.Average,
    weight_unit: EmissionFreightWeightUnitEnum.Kg,
    weight: 200,
    auto_transshipment: autoTransshipment,
  },
  legs: [
    {
      id: 4,
      to: {
        id:
          process.env.NODE_ENV === 'development'
            ? '5dbe8cb3-c1b7-4a91-aea6-c16451171170'
            : '44eab694-feb0-4dfd-b3f7-1d6aba5427ae',
        name: 'Dartford',
        display_name: 'Dartford, Kent, England, United Kingdom',
        type: CalculatorLocationTypeEnum.CITY,
        country: 'United Kingdom',
        country_code_alpha_2: 'gb',
        country_code_alpha_3: 'gbr',
        coordinates: [51.44431, 0.21807],
      },
      from: {
        id:
          process.env.NODE_ENV === 'development'
            ? '5dbe8cb3-c1b7-4a91-aea6-c16451171170'
            : '44eab694-feb0-4dfd-b3f7-1d6aba5427ae',
        name: 'Dartford',
        display_name: 'Dartford, Kent, England, United Kingdom',
        type: CalculatorLocationTypeEnum.CITY,
        country: 'United Kingdom',
        country_code_alpha_2: 'gb',
        country_code_alpha_3: 'gbr',
        coordinates: [51.44431, 0.21807],
      },
      isSaved: true,
      detail: {
        vehicle_used: 'LSW',
        sea_restricted_areas: seaRestrictedAreas,
        ferry_preference: ferryPreference,
      },
    },
    {
      distance_unit: EmissionFreightDistanceUnitEnum.Km,
      isSaved: true,
      id: 1,
      to: {
        id:
          process.env.NODE_ENV === 'development'
            ? '0cd2c276-d5c2-41a5-8eb7-303091553e51'
            : '0cd2c276-d5c2-41a5-8eb7-303091553e51',
        name: 'Dover',
        display_name: 'Dover, United Kingdom',
        type: CalculatorLocationTypeEnum.PORT,
        country: 'United Kingdom',
        country_code_alpha_2: 'gb',
        country_code_alpha_3: 'gbr',
        coordinates: [51.12254, 1.3298],
        unlocode: 'GBDVR',
      },
      from: {
        id:
          process.env.NODE_ENV === 'development'
            ? '5dbe8cb3-c1b7-4a91-aea6-c16451171170'
            : '44eab694-feb0-4dfd-b3f7-1d6aba5427ae',
        name: 'Dartford',
        display_name: 'Dartford, Kent, England, United Kingdom',
        type: CalculatorLocationTypeEnum.CITY,
        country: 'United Kingdom',
        country_code_alpha_2: 'gb',
        country_code_alpha_3: 'gbr',
        coordinates: [51.44431, 0.21807],
      },
      detail: {
        emission_standard: EmissionFreightVehicleEmissionStandardEnum.Euro_6,
        empty_running: 0.17,
        fuel_type: CalculatorFreightFuelTypeEnum.Diesel,
        load_factor: 0.8,
        vehicle_used: 'HA3',
        sea_restricted_areas: seaRestrictedAreas,
        ferry_preference: ferryPreference,
      },
    },
    {
      distance_unit: EmissionFreightDistanceUnitEnum.Km,
      isSaved: true,
      id: 2,
      to: {
        id:
          process.env.NODE_ENV === 'development'
            ? 'f66bd90d-3323-45a0-beaf-fa16cf216664'
            : 'f66bd90d-3323-45a0-beaf-fa16cf216664',
        name: 'Calais',
        display_name: 'Calais, France',
        type: CalculatorLocationTypeEnum.PORT,
        country: 'France',
        country_code_alpha_2: 'fr',
        country_code_alpha_3: 'fra',
        coordinates: [50.96555, 1.85227],
        unlocode: 'FRCQF',
      },
      from: {
        id:
          process.env.NODE_ENV === 'development'
            ? '0cd2c276-d5c2-41a5-8eb7-303091553e51'
            : '0cd2c276-d5c2-41a5-8eb7-303091553e51',
        name: 'Dover',
        display_name: 'Dover, United Kingdom',
        type: CalculatorLocationTypeEnum.PORT,
        country: 'United Kingdom',
        country_code_alpha_2: 'gb',
        country_code_alpha_3: 'gbr',
        coordinates: [51.12254, 1.3298],
        unlocode: 'GBDVR',
      },
      detail: {
        vehicle_used: 'SRR',
        sea_restricted_areas: seaRestrictedAreas,
        ferry_preference: ferryPreference,
      },
    },
    {
      distance_unit: EmissionFreightDistanceUnitEnum.Km,
      isSaved: true,
      id: 3,
      to: {
        id:
          process.env.NODE_ENV === 'development'
            ? '66d7f554-f754-4333-b527-f9688a9ac091'
            : '66d7f554-f754-4333-b527-f9688a9ac091',
        name: 'Paris',
        display_name: 'Paris, Paris, Ile-de-France, Metropolitan France, France',
        type: CalculatorLocationTypeEnum.CITY,
        country: 'France',
        country_code_alpha_2: 'fr',
        country_code_alpha_3: 'fra',
        coordinates: [48.8535, 2.34839],
        unlocode: 'FRPAR',
      },
      from: {
        id:
          process.env.NODE_ENV === 'development'
            ? 'f66bd90d-3323-45a0-beaf-fa16cf216664'
            : 'f66bd90d-3323-45a0-beaf-fa16cf216664',
        name: 'Calais',
        display_name: 'Calais, France',
        type: CalculatorLocationTypeEnum.PORT,
        country: 'France',
        country_code_alpha_2: 'fr',
        country_code_alpha_3: 'fra',
        coordinates: [50.96555, 1.85227],
        unlocode: 'FRCQF',
      },
      detail: {
        emission_standard: EmissionFreightVehicleEmissionStandardEnum.Euro_6,
        empty_running: 0.17,
        fuel_type: CalculatorFreightFuelTypeEnum.Diesel,
        load_factor: 0.8,
        vehicle_used: 'HA3',
        sea_restricted_areas: seaRestrictedAreas,
        ferry_preference: ferryPreference,
      },
    },
    {
      id: 5,
      from: {
        id:
          process.env.NODE_ENV === 'development'
            ? '66d7f554-f754-4333-b527-f9688a9ac091'
            : '66d7f554-f754-4333-b527-f9688a9ac091',
        name: 'Paris',
        display_name: 'Paris, Paris, Ile-de-France, Metropolitan France, France',
        type: CalculatorLocationTypeEnum.CITY,
        country: 'France',
        country_code_alpha_2: 'fr',
        country_code_alpha_3: 'fra',
        coordinates: [48.8535, 2.34839],
        unlocode: 'FRPAR',
      },
      to: {
        id:
          process.env.NODE_ENV === 'development'
            ? '66d7f554-f754-4333-b527-f9688a9ac091'
            : '66d7f554-f754-4333-b527-f9688a9ac091',
        name: 'Paris',
        display_name: 'Paris, Paris, Ile-de-France, Metropolitan France, France',
        type: CalculatorLocationTypeEnum.CITY,
        country: 'France',
        country_code_alpha_2: 'fr',
        country_code_alpha_3: 'fra',
        coordinates: [48.8535, 2.34839],
        unlocode: 'FRPAR',
      },
      isSaved: true,
      detail: {
        vehicle_used: 'LSW',
        sea_restricted_areas: seaRestrictedAreas,
        ferry_preference: ferryPreference,
      },
    },
  ],
});
