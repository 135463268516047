import type { EmptyStateProps } from '@pledge-earth/product-language';
import { Card, EmptyState, FeaturedIcon } from '@pledge-earth/product-language';
import type { ReactNode } from 'react';

export interface Props {
  image?: ReactNode;
  title: ReactNode;
  description: ReactNode;
  primaryAction?: EmptyStateProps['primaryAction'];
  secondaryAction?: EmptyStateProps['secondaryAction'];
}

export function ErrorBase(props: Props) {
  return (
    <Card className="mx-auto max-w-md bg-default p-12">
      <EmptyState
        image={props.image ? <FeaturedIcon size="40">{props.image}</FeaturedIcon> : undefined}
        heading={props.title}
        description={props.description}
        primaryAction={props.primaryAction}
        secondaryAction={props.secondaryAction}
      />
    </Card>
  );
}
