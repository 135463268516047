import { FormattedMessage } from 'react-intl';
import { Text, Heading, Paragraph, BannerMessage, PlainButton } from '@pledge-earth/product-language';

import type { MessageId } from '../../../locales/types';

export enum ErrorCode {
  LINK_INVALID = 'LINK_INVALID',
  LINK_INVALID_NO_EMAIL = 'LINK_INVALID_NO_EMAIL',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  CODE_EXPIRED = 'CODE_EXPIRED',
  CODE_INVALID = 'CODE_INVALID',
  SESSION_MISSING = 'SESSION_MISSING',
  ERROR_UNKNOWN = 'ERROR_UNKNOWN',
  RESEND_ERROR = 'RESEND_ERROR',
}

const NO_RESEND_ERRORS = [
  ErrorCode.RESEND_ERROR,
  ErrorCode.LINK_INVALID_NO_EMAIL,
  ErrorCode.ERROR_UNKNOWN,
  ErrorCode.SESSION_MISSING,
] as const;

const ERROR_CODE_TO_TRANSLATION: { [key in ErrorCode]: MessageId } = {
  LINK_INVALID: 'auth.confirm_sign_up_error.code_invalid', // malformed link (missing or empty parameters)
  SESSION_EXPIRED: 'auth.confirm_sign_up_error.code_expired', // cognito returned session expired
  CODE_EXPIRED: 'auth.confirm_sign_up_error.code_expired', // cognito returned code expired
  CODE_INVALID: 'auth.confirm_sign_up_error.code_invalid', // cognito returned code invalid
  SESSION_MISSING: 'auth.confirm_sign_up_error.session_missing', // no session in local storage
  ERROR_UNKNOWN: 'auth.confirm_sign_up_error.error_unknown',
  RESEND_ERROR: 'auth.confirm_sign_up_error.send_error',
  LINK_INVALID_NO_EMAIL: 'auth.confirm_sign_up_error.link_invalid_no_email',
};

export function ConfirmSignUpError({
  sendEmail,
  emailResent,
  errorCode,
}: {
  sendEmail: () => void;
  emailResent?: boolean;
  errorCode?: ErrorCode;
}) {
  const errorTranslation = ERROR_CODE_TO_TRANSLATION[errorCode || ErrorCode.ERROR_UNKNOWN];

  return (
    <div className="flex flex-col gap-8">
      <Heading level="h2" className="mb-0">
        <FormattedMessage id="auth.confirm_sign_up_error.title" />
      </Heading>

      {emailResent && errorCode !== ErrorCode.RESEND_ERROR ? (
        <BannerMessage>
          <Text>
            <FormattedMessage id="auth.self_sign_up.verify_your_email.resent" />
          </Text>
        </BannerMessage>
      ) : (
        <section>
          <Paragraph>
            <FormattedMessage
              id={errorTranslation}
              values={{
                support: <a href="mailto: support@pledge.io">support@pledge.io</a>,
              }}
            />
          </Paragraph>
          {errorCode === ErrorCode.SESSION_MISSING && (
            <Paragraph>
              <FormattedMessage id="auth.confirm_sign_up_error.session_missing.incognito" />
            </Paragraph>
          )}
        </section>
      )}
      {(errorCode && (NO_RESEND_ERRORS as readonly ErrorCode[]).includes(errorCode)) || emailResent ? null : (
        <PlainButton onPress={sendEmail} className="self-start">
          <FormattedMessage id="auth.confirm_sign_up_error.send_email" />
        </PlainButton>
      )}
    </div>
  );
}
