import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import type { NavLinkProps as ReactRouterNavLinkProps } from 'react-router-dom';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';

import { useIsTestMode } from '../../hooks/useIsTestMode';
import { configureToIfTestMode } from '../../hooks/useNavigateWithTestMode';

export const ReactRouterNavLinkWithTestMode = forwardRef(
  ({ to, ...props }: ReactRouterNavLinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
    const testModeEnabled = useIsTestMode();

    return <ReactRouterNavLink ref={ref} to={configureToIfTestMode(testModeEnabled, to)} {...props} />;
  },
);
