import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';

// Sentry
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    tracePropagationTargets: process.env.REACT_APP_API_ORIGIN
      ? [new RegExp(process.env.REACT_APP_API_ORIGIN)]
      : undefined,
    tracesSampleRate:
      process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE != null
        ? parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
        : undefined,
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });
}
