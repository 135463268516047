import { Button, Text, twMerge } from '@pledge-earth/product-language';
import type { ReactNode } from 'react';
import { useLocation, type To } from 'react-router-dom';

import type { ClientRoleEnum } from '../../services/graphql/generated';
import { ACL } from '../ACL/ACL';
import { ReactRouterNavLinkWithTestMode } from '../LinkWithTestMode/NavLinkWithTestMode';

export function NavSectionHeading(props: { children: React.ReactNode; className?: string }) {
  return (
    <Text className={twMerge('block truncate text-heading-xs transition-opacity', props.className)} variant="subdued">
      {props.children}
    </Text>
  );
}

export function NavSection(props: { children: React.ReactNode; roles: ClientRoleEnum[] | '*' }) {
  return (
    <ACL roles={props.roles}>
      <div className="flex flex-col gap-1">{props.children}</div>
    </ACL>
  );
}

export function NavMenu(props: { children: React.ReactNode }) {
  return <ul className="mb-0 flex flex-col gap-1">{props.children}</ul>;
}

export function NavItem(props: { children: React.ReactNode; roles: ClientRoleEnum[] | '*' }) {
  return (
    <ACL roles={props.roles}>
      <li>{props.children}</li>
    </ACL>
  );
}

export function NavItemLink(props: { id?: string; to: To; target?: string; children: ReactNode }) {
  return (
    <Button
      {...props}
      elementType={ReactRouterNavLinkWithTestMode}
      variant="subtle"
      className={twMerge(
        'w-full justify-start overflow-hidden *:grow *:justify-start *:gap-4',
        "[&[aria-current='page']]:bg-default-subdued-hover",
      )}
    />
  );
}

export function NavItemButton(props: { to: string; children: ReactNode }) {
  const { pathname: locationPathname } = useLocation();

  // logic copied from NavLink: https://github.com/remix-run/react-router/blob/a072d9405616d2be67db95670577ac3288b8381b/packages/react-router/lib/dom/lib.tsx#L854
  const toPathname = props.to;
  const endSlashPosition = toPathname !== '/' && toPathname.endsWith('/') ? toPathname.length - 1 : toPathname.length;

  const isActive =
    locationPathname === toPathname ||
    (locationPathname.startsWith(toPathname) && locationPathname.charAt(endSlashPosition) === '/');

  return (
    <Button
      {...props}
      variant="subtle"
      className={twMerge(
        'w-full justify-start overflow-hidden *:grow *:justify-start *:gap-4',
        isActive ? 'bg-default-subdued-hover' : '',
      )}
    />
  );
}
