import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { LoadingFullScreen } from '../../components/Loading/LoadingFullScreen';

export function AuthLayout() {
  return (
    <Suspense fallback={<LoadingFullScreen />}>
      <Outlet />
    </Suspense>
  );
}
