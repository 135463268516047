import type { ReactNode } from 'react';
import { Suspense } from 'react';
import Lottie from 'react-lottie';
import { Outlet } from 'react-router-dom';

import { Footer } from '../../components/Footer/Footer';
import { LoadingFullScreen } from '../../components/Loading/LoadingFullScreen';
import animationData from '../../components/LottieAnimations/pledgeLoadingPurple.json';

const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

interface OneColumnLayoutProps {
  children?: React.ReactNode;
  footer?: ReactNode;
}

export function OneColumnLayout({ children, footer }: OneColumnLayoutProps) {
  return (
    <div id="OneColumnLayout" className="mx-auto flex min-h-full max-w-[1200px] flex-col gap-4 px-5 pb-6 pt-4">
      <header className="flex h-12 items-center">
        <a href="/" aria-label="Home">
          <Lottie options={defaultLottieOptions} isClickToPauseDisabled={true} height={28} width={133} />
        </a>
      </header>
      <main className="grow">
        <Suspense fallback={<LoadingFullScreen />}>{children ?? <Outlet />}</Suspense>
      </main>
      <footer>{footer ?? <Footer />}</footer>
    </div>
  );
}
