import { createSlice } from '@reduxjs/toolkit';

import { example1State } from '../../components/EmissionCalculator/Examples/example1State';
import { example2State } from '../../components/EmissionCalculator/Examples/example2State';
import type { CalculatorLocation, CalculatorParentState } from '../../components/EmissionCalculator/types';

import { locationsAdapter } from './entity';

const getLocations = (state: CalculatorParentState): CalculatorLocation[] =>
  state.legs.flatMap((leg) => [leg.from, leg.to, ...(leg.stops ?? [])]).filter((location) => location != null);

// This store is a cache for locations so they can be re-used when rendering the calculator side panel details. Should
// be replaced by an Apollo cache in the near future when calculator endpoints are moved to GraphQL.
const { actions, reducer } = createSlice({
  name: 'locations',
  initialState: locationsAdapter.getInitialState(undefined, [
    // we prefill the cache with locations from the examples as they may never be retrieved from the locations endpoint
    ...getLocations(example1State()),
    ...getLocations(example2State()),
  ]),
  reducers: {
    addMany: locationsAdapter.addMany,
  },
});

export const { addMany } = actions;

export { reducer };
