import type { ReactEventHandler, ReactNode } from 'react';
import { useState, useCallback } from 'react';
import { twMerge } from '@pledge-earth/product-language';

interface AvatarImageProps extends Pick<React.ComponentPropsWithoutRef<'img'>, 'className' | 'src' | 'onError'> {
  fallback?: ReactNode;
}

export function AvatarImage({ fallback, className, src, onError }: AvatarImageProps) {
  const [showFallback, setShowFallback] = useState(!src);

  const augmentedOnError = useCallback<ReactEventHandler<HTMLImageElement>>(
    (error) => {
      setShowFallback(true);
      if (onError) {
        onError(error);
      }
    },
    [setShowFallback, onError],
  );

  if (showFallback || !src) {
    return <span className={twMerge('AvatarImage AvatarImage--text', className)}>{fallback}</span>;
  }

  return (
    <img
      className={twMerge('AvatarImage AvatarImage--img object-scale-down', className)}
      onError={augmentedOnError}
      alt=""
      src={src}
    />
  );
}
