import { FormattedMessage } from 'react-intl';
import { Link } from '@pledge-earth/product-language';

import { ExternalLink } from '../Link/ExternalLink';
import { CookieYesLink } from '../CookieYesLink/CookieYesLink';

export function Footer() {
  return (
    <div className="flex items-center justify-center gap-4 text-center">
      <ExternalLink variant="monochrome" href="https://www.pledge.io/privacy" noExternalIcon={true}>
        <FormattedMessage id="privacy_policy" />
      </ExternalLink>
      <span>&bull;</span>
      <ExternalLink variant="monochrome" href="https://www.pledge.io/terms" noExternalIcon={true}>
        <FormattedMessage id="terms" />
      </ExternalLink>
      <span>&bull;</span>
      <CookieYesLink>
        {(props) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link {...props} href="#" variant="monochrome">
            <FormattedMessage id="cookie-settings" />
          </Link>
        )}
      </CookieYesLink>
    </div>
  );
}
