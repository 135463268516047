import { useFlags } from '@unleash/proxy-client-react';
import type { ReactNode } from 'react';

interface FeatureFlagProps {
  children: ReactNode;
  fallback?: ReactNode;
  isEnabled: (enabledFlags: Set<string>) => boolean;
}

export function FeatureFlags({ children, fallback = null, isEnabled }: FeatureFlagProps) {
  const flags = useFlags();

  const enabledFlags = new Set(flags.filter((flag) => flag.enabled).map((flag) => flag.name));

  return isEnabled(enabledFlags) ? children : fallback;
}

export function allEnabled(flags: string[]) {
  return (enabledFlags: Set<string>) => flags.some((entitlement) => enabledFlags.has(entitlement));
}
