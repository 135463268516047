import type { ReactNode } from 'react';

import { ExternalLink } from '../../components/Link/ExternalLink';
import { OneColumnLayout } from '../OneColumn/OneColumnLayout';

/**
 * This layout is used when there is an error in the provider stack, therefore it doesn't have access to the router or
 * translations.
 */
export function LastResortLayout({ children }: { children: ReactNode }) {
  return (
    <OneColumnLayout
      footer={
        <div className="flex items-center justify-center gap-4">
          <ExternalLink variant="monochrome" href="https://www.pledge.io/privacy" noExternalIcon={true}>
            Privacy Policy
          </ExternalLink>
          <span>&bull;</span>
          <ExternalLink variant="monochrome" href="https://www.pledge.io/terms" noExternalIcon={true}>
            Terms
          </ExternalLink>
        </div>
      }
    >
      {children}
    </OneColumnLayout>
  );
}
