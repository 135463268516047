import type { CalendarDate } from '@internationalized/date';

import type { MessageId, MessageIdPart } from '../../locales/types';
import type { GetPublicUntrackedEmissionQuery } from '../../services/graphql-public/generated';
import type {
  EmissionModeEnum,
  EmissionDetailScopeEnum,
  EmissionDetailSubtypeEnum,
  EmissionDetailTypeEnum,
  EmissionFactorUnitEnum,
  EmissionFreightCargoTypeEnum,
  EmissionFreightDistanceUnitEnum,
  EmissionFreightWeightUnitEnum,
  EmissionTypeEnum,
  GetUntrackedEmissionQuery,
} from '../../services/graphql/generated';
import type { EmissionDetailTypeGroupEnum } from '../EmissionOverview/types';
import type { ClarityContext } from '../EmissionTabs/EmissionContext/ClarityDetail/types';

import type { AirDetailFormData } from './Route/CalculatorRouteLeg/LegDetailForms/AirDetailForm';
import type { InlandWaterwayDetailFormData } from './Route/CalculatorRouteLeg/LegDetailForms/InlandWaterwayDetailForm';
import type { RailDetailFormData } from './Route/CalculatorRouteLeg/LegDetailForms/RailDetailForm';
import type { RoadDetailFormData } from './Route/CalculatorRouteLeg/LegDetailForms/RoadDetailForm';
import type { SeaDetailFormData } from './Route/CalculatorRouteLeg/LegDetailForms/SeaDetailForm';
import type { SUPPORTED_FREIGHT_INPUT_MODES } from './constants/constants';

enum CalculatorSearchGroupTypeEnum {
  PORT = 'PORT',
  AIRPORT = 'AIRPORT',
  CITY = 'CITY',
  CARRIER = 'CARRIER',
  ADDRESS = 'ADDRESS',
}

export interface CalculatorSearchGroup<T> {
  options: T;
  type: CalculatorSearchGroupTypeEnum;
  maxScore?: number;
}

export enum CalculatorLocationTypeEnum {
  PORT = 'PORT',
  AIRPORT = 'AIRPORT',
  CITY = 'CITY',
  ADDRESS = 'ADDRESS',
}

export interface CalculatorLocation {
  id: string;
  display_name: string;
  type: CalculatorLocationTypeEnum;
  name?: string | null;
  unlocode?: string;
  iata_code?: string;
  country?: string;
  country_code_alpha_2?: string;
  country_code_alpha_3?: string;
  coordinates?: [number, number];
}

export enum CalculatorCarrierTypeEnum {
  TRUCK = 'TRUCK',
  CONTAINER_SHIP = 'CONTAINER_SHIP',
  AIRLINE = 'AIRLINE',
}

export interface CalculatorCarrier {
  id: string;
  name: string;
  code: string;
  type: CalculatorCarrierTypeEnum;
}

export interface CalculatorVessel {
  id: string;
  name: string;
  imo: string;
}

export interface CalculatorAircraftModel {
  id: string;
  manufacturer: string;
  model: string;
  aircraft_type: string | null;
  iata_code: string | null;
}

export type ActiveKeyType = string | number | null;

export interface CalculatorInputLegV2 {
  id: number;
  distance?: number;
  distance_unit?: EmissionFreightDistanceUnitEnum;
  starting_country_code?: string;
  from?: CalculatorLocation;
  to?: CalculatorLocation;
  detail?: CalculationInputEditableDetails;
  isSaved?: boolean;
  stops?: CalculatorLocation[];
  is_auto_transshipment?: boolean;
}

export interface CalculatorInputLocationDetails {
  type: LogisticHubsTypeEnum | null;
  is_refrigerated?: boolean;
  is_auto_transshipment?: boolean;
}

export interface CalculatorInputLeg extends CalculatorInputLegV2 {
  from_detail?: CalculatorInputLocationDetails;
  to_detail?: CalculatorInputLocationDetails;
  is_auto_transshipment?: false;
}

export interface CalculatorParentState {
  cargoDetail: CalculationInputCargoDetail;
  legs: CalculatorInputLegV2[];
}

export interface CalculationInputCargoDetail {
  weight?: number;
  weight_unit?: EmissionFreightWeightUnitEnum;
  cargo_type?: EmissionFreightCargoTypeEnum;
  date?: CalendarDate;
  auto_transshipment?: boolean;
  metadata?: Record<string, string>;
}

export type CalculationInputEditableDetails = Partial<
  AirDetailFormData & InlandWaterwayDetailFormData & RailDetailFormData & RoadDetailFormData & SeaDetailFormData
>;

type EmissionBreakdownType =
  | EmissionDetailScopeEnum
  | EmissionDetailTypeEnum
  | EmissionDetailSubtypeEnum
  | EmissionDetailTypeGroupEnum;

export type EmissionFreightModeEnum = (typeof SUPPORTED_FREIGHT_INPUT_MODES)[number];

export type CalculationResultParent =
  | GetUntrackedEmissionQuery['untracked_emission']
  | GetPublicUntrackedEmissionQuery['public_untracked_emission'];

interface CalculationResultEmissionBreakdown {
  co2e: {
    value: number;
    unit: EmissionFreightWeightUnitEnum;
  };
  co2?: {
    value: number;
    unit: EmissionFreightWeightUnitEnum;
  };
  ch4?: {
    value: number;
    unit: EmissionFreightWeightUnitEnum;
  };
  n2o?: {
    value: number;
    unit: EmissionFreightWeightUnitEnum;
  };
  nox?: {
    value: number;
    unit: EmissionFreightWeightUnitEnum;
  };
  so2?: {
    value: number;
    unit: EmissionFreightWeightUnitEnum;
  };
  pm?: {
    value: number;
    unit: EmissionFreightWeightUnitEnum;
  };
  nmhc?: {
    value: number;
    unit: EmissionFreightWeightUnitEnum;
  };
  intensity: {
    value: number;
    unit: EmissionFactorUnitEnum;
  };
  breakdown?: {
    [key in EmissionBreakdownType]: CalculationResultEmissionBreakdown;
  };
}

export interface CalculationResultSegment {
  type: EmissionTypeEnum;
  mode: EmissionModeEnum;
  emissions: {
    co2e: {
      value: number;
      unit: EmissionFreightWeightUnitEnum;
    };
    distance?: {
      value: number;
      unit: EmissionFreightDistanceUnitEnum;
    };
    breakdown: {
      [key in EmissionBreakdownType]?: CalculationResultEmissionBreakdown;
    };
  };
  characteristics?: {
    is_urban?: boolean;
    is_motorway?: boolean;
    is_diesel?: boolean;
    is_electric?: boolean;
    is_hra?: boolean;
    is_ferry?: boolean;
    is_train?: boolean;
    region?: string;
    country_code?: string;
  };
  clarity: ClarityContext;
  path?: {
    polyline_reference: {
      start_index: number;
      end_index: number;
    };
  };
}

export const EmissionFreightVehicleCodeEnum = Object.freeze({
  A: 'A',
  Bcc: 'BCC',
  Bcv: 'BCV',
  Bcv1: 'BCV1',
  Bcv2: 'BCV2',
  Bcvcc: 'BCVCC',
  Bmv: 'BMV',
  Bmv1: 'BMV1',
  Bmv2: 'BMV2',
  Bmv3: 'BMV3',
  Bpc: 'BPC',
  Bpc1: 'BPC1',
  Bpc2: 'BPC2',
  Bpc3: 'BPC3',
  Btv: 'BTV',
  Btv1: 'BTV1',
  Btv2: 'BTV2',
  H: 'H',
  H1: 'H1',
  H2: 'H2',
  H3: 'H3',
  Ha1: 'HA1',
  Ha2: 'HA2',
  Ha3: 'HA3',
  Ha4: 'HA4',
  Ha5: 'HA5',
  Hr1: 'HR1',
  Hr2: 'HR2',
  Hr3: 'HR3',
  Hr4: 'HR4',
  Hr5: 'HR5',
  Lsc: 'LSC',
  Lsst: 'LSST',
  Lst: 'LST',
  Lsw: 'LSW',
  NaHac: 'NA_HAC',
  NaHd: 'NA_HD',
  NaHe: 'NA_HE',
  NaHf: 'NA_HF',
  NaHg: 'NA_HG',
  NaHhb: 'NA_HHB',
  NaHltl: 'NA_HLTL',
  NaHmi: 'NA_HMI',
  NaHmo: 'NA_HMO',
  NaHp: 'NA_HP',
  NaHs: 'NA_HS',
  NaHt: 'NA_HT',
  NaHtl: 'NA_HTL',
  P23A: 'P23:A',
  P23Ab: 'P23:AB',
  P23Af: 'P23:AF',
  P23Bcc: 'P23:BCC',
  P23Bcv: 'P23:BCV',
  P23Bcv1: 'P23:BCV1',
  P23Bcv2: 'P23:BCV2',
  P23Bcvcc: 'P23:BCVCC',
  P23Bmv: 'P23:BMV',
  P23Bmv1: 'P23:BMV1',
  P23Bmv2: 'P23:BMV2',
  P23Bmv3: 'P23:BMV3',
  P23Bmv4: 'P23:BMV4',
  P23Bpc: 'P23:BPC',
  P23Bpc1: 'P23:BPC1',
  P23Bpc2: 'P23:BPC2',
  P23Bpc3: 'P23:BPC3',
  P23Btv: 'P23:BTV',
  P23Btv1: 'P23:BTV1',
  P23Btv2: 'P23:BTV2',
  P23H: 'P23:H',
  P23H1: 'P23:H1',
  P23H2: 'P23:H2',
  P23H3: 'P23:H3',
  P23Ha1: 'P23:HA1',
  P23Ha2: 'P23:HA2',
  P23Ha3: 'P23:HA3',
  P23Ha4: 'P23:HA4',
  P23Ha5: 'P23:HA5',
  P23Hr1: 'P23:HR1',
  P23Hr2: 'P23:HR2',
  P23Hr3: 'P23:HR3',
  P23Hr4: 'P23:HR4',
  P23Hr5: 'P23:HR5',
  P23Lsc: 'P23:LSC',
  P23Lslb: 'P23:LSLB',
  P23Lsst: 'P23:LSST',
  P23Lst: 'P23:LST',
  P23Lsw: 'P23:LSW',
  P23NaHac: 'P23:NA_HAC',
  P23NaHd: 'P23:NA_HD',
  P23NaHe: 'P23:NA_HE',
  P23NaHf: 'P23:NA_HF',
  P23NaHg: 'P23:NA_HG',
  P23NaHhb: 'P23:NA_HHB',
  P23NaHltl: 'P23:NA_HLTL',
  P23NaHmi: 'P23:NA_HMI',
  P23NaHmo: 'P23:NA_HMO',
  P23NaHp: 'P23:NA_HP',
  P23NaHr: 'P23:NA_HR',
  P23NaHs: 'P23:NA_HS',
  P23NaHt: 'P23:NA_HT',
  P23NaHtl: 'P23:NA_HTL',
  P23Sbc: 'P23:SBC',
  P23Sbc1: 'P23:SBC1',
  P23Sbc2: 'P23:SBC2',
  P23Sbc3: 'P23:SBC3',
  P23Sbc4: 'P23:SBC4',
  P23Sbc5: 'P23:SBC5',
  P23Sbc6: 'P23:SBC6',
  P23Scs: 'P23:SCS',
  P23Sgc: 'P23:SGC',
  P23Sgc1: 'P23:SGC1',
  P23Sgc2: 'P23:SGC2',
  P23Sgc3: 'P23:SGC3',
  P23Sgc4: 'P23:SGC4',
  P23Sot: 'P23:SOT',
  P23Sot1: 'P23:SOT1',
  P23Sot2: 'P23:SOT2',
  P23Sot3: 'P23:SOT3',
  P23Sot4: 'P23:SOT4',
  P23Sot5: 'P23:SOT5',
  P23Sot6: 'P23:SOT6',
  P23Sot7: 'P23:SOT7',
  P23Sot8: 'P23:SOT8',
  P23Srb: 'P23:SRB',
  P23Srb1: 'P23:SRB1',
  P23Srb2: 'P23:SRB2',
  P23Srb3: 'P23:SRB3',
  P23Srb4: 'P23:SRB4',
  P23Srp: 'P23:SRP',
  P23Srp1: 'P23:SRP1',
  P23Srp2: 'P23:SRP2',
  P23Srp3: 'P23:SRP3',
  P23Srp4: 'P23:SRP4',
  P23Srp5: 'P23:SRP5',
  P23Srr: 'P23:SRR',
  P23Srr1: 'P23:SRR1',
  P23Srr2: 'P23:SRR2',
  P23Srr3: 'P23:SRR3',
  P23Srr4: 'P23:SRR4',
  P23Sv: 'P23:SV',
  P23Sv1: 'P23:SV1',
  P23Sv2: 'P23:SV2',
  P23Sv3: 'P23:SV3',
  P23T: 'P23:T',
  P23V: 'P23:V',
  Sbc: 'SBC',
  Sbc1: 'SBC1',
  Sbc2: 'SBC2',
  Sbc3: 'SBC3',
  Scs: 'SCS',
  Sgc: 'SGC',
  Sgc1: 'SGC1',
  Sgc2: 'SGC2',
  Srp: 'SRP',
  Srr: 'SRR',
  T: 'T',
  V: 'V',
});
export type EmissionFreightVehicleCodeEnum =
  (typeof EmissionFreightVehicleCodeEnum)[keyof typeof EmissionFreightVehicleCodeEnum];

export const LogisticHubsTypeEnum = Object.freeze({
  LST: 'LST',
  LSST: 'LSST',
  LSC: 'LSC',
  LSW: 'LSW',
  'P23:LSC': 'P23:LSC',
  'P23:LSLB': 'P23:LSLB',
  'P23:LSST': 'P23:LSST',
  'P23:LST': 'P23:LST',
  'P23:LSW': 'P23:LSW',
});
export type LogisticHubsTypeEnum = (typeof LogisticHubsTypeEnum)[keyof typeof LogisticHubsTypeEnum];

export interface CalculationError {
  titleKey: MessageId;
  descriptionKey?: MessageId;
  type?: MessageIdPart<'emissions.calculator.results.error', 'title'>;
  message?: string;
}

export type CalculationErrorType =
  | 'route_calculation_error'
  | 'p23_vehicle_mapping_error'
  | 'truck_max_payload_exceeded_error';
