import { all, takeEvery } from 'redux-saga/effects';
import store from 'store';

import { themeChanged } from './reducers';

function* changeTheme({ payload: { theme } }: ReturnType<typeof themeChanged>) {
  yield store.set(`app.settings.theme`, theme);
}

export function* sagas() {
  yield all([takeEvery(themeChanged, changeTheme)]);
}
