import * as Sentry from '@sentry/react';
import { Suspense, lazy } from 'react';
import type { UIMatch } from 'react-router-dom';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { ACL } from '../components/ACL/ACL';
import { DiscoverLayout } from '../layouts/Discover/DiscoverLayout';
import { AuthLayout } from '../layouts/Auth/AuthLayout';
import { OneColumnLayout } from '../layouts/OneColumn/OneColumnLayout';
import { MainLayout } from '../layouts/Main/MainLayout';
import { RootLayout } from '../layouts/RootLayout';
import { ClientRoleEnum } from '../services/graphql/generated';
import { RouterErrorBoundaryFallback500Redirect } from '../components/RouterErrorBoundary/RouterErrorBoundary';
import { FeatureFlag } from '../components/FeatureFlag/FeatureFlag';
import {
  Entitlement,
  hasEntitlement,
  PLATFORM_CLIENTS_ENTITLEMENT,
  PLATFORM_DIRECTORY_CLIENT_ENTITLEMENT,
  PLATFORM_DIRECTORY_SUPPLIER_ENTITLEMENT,
  PLATFORM_SUPPLIERS_ENTITLEMENT,
} from '../components/Entitlement/Entitlement';

import { RequireCompletedSignUp } from './guards/RequireCompletedSignUp';
import { RequireActiveSubscription } from './guards/RequireActiveSubscription';
import { RequireAuthenticated } from './guards/RequireAuthenticated';
import { RequireFeatureFlags } from './guards/RequireFeatureFlags';
import { RequireUserLoaded } from './guards/RequireUserLoaded';
import { RedirectTo404 } from './guards/RedirectTo404';
import { LiveModePrompt } from './guards/LiveModePrompt';
import { RACRouterProvider } from './providers/RACRouterProvider';

const Home = lazy(() => import('../pages/home/Home'));
const DashboardEmissionsOverview = lazy(() => import('../pages/dashboards/emissions/DashboardEmissionsOverview'));
const DashboardEmissionsClient = lazy(() => import('../pages/dashboards/emissions/DashboardEmissionsClient'));
const DashboardEmissionsSupplier = lazy(() => import('../pages/dashboards/emissions/DashboardEmissionsSupplier'));
const DashboardEmissionsIntensity = lazy(() => import('../pages/dashboards/emissions/DashboardEmissionsIntensity'));
const DashboardEmissionsAccuracy = lazy(() => import('../pages/dashboards/emissions/DashboardEmissionsAccuracy'));
const DashboardEmissionsSeaFreight = lazy(() => import('../pages/dashboards/emissions/DashboardEmissionsSeaFreight'));
const DashboardEmissionsAirFreight = lazy(() => import('../pages/dashboards/emissions/DashboardEmissionsAirFreight'));
const DashboardEmissionsNorthAmericaRoadFreight = lazy(
  () => import('../pages/dashboards/emissions/DashboardEmissionsNorthAmericaRoadFreight'),
);
const Imports = lazy(() => import('../pages/imports/Imports'));
const Emissions = lazy(() => import('../pages/emissions/Emissions'));
const EmissionsExportDownload = lazy(() => import('../pages/emissions/EmissionsExportDownload'));

const EmissionDetailsLayout = lazy(() => import('../pages/emissions/EmissionDetails/Page/EmissionDetailsPage'));
const EmissionDetails = lazy(() => import('../pages/emissions/EmissionDetails/Page/EmissionDetails'));
const EmissionDetailsAccuracyView = lazy(
  () => import('../pages/emissions/EmissionDetails/Page/EmissionDetailsAccuracyView'),
);
const EmissionDetailsBreakdownView = lazy(
  () => import('../pages/emissions/EmissionDetails/Page/EmissionDetailsBreakdownView'),
);
const EmissionDetailsClarityView = lazy(
  () => import('../pages/emissions/EmissionDetails/Page/EmissionDetailsClarityView'),
);

const EmissionDetailsDrawer = lazy(() => import('../pages/emissions/EmissionDetails/Drawer/EmissionDetailsDrawer'));
const EmissionChildDetails = lazy(() => import('../pages/emissions/EmissionDetails/Drawer/EmissionChildDetails'));

const ReportsEmissions = lazy(() => import('../pages/reports/emissions/EmissionReport'));
const CreateEmissionsReport = lazy(() => import('../pages/reports/emissions/CreateEmissionsReport'));
const ChooseReport = lazy(() => import('../pages/reports/emissions/ChooseReport'));
const GlecReport = lazy(() => import('../pages/reports/emissions/glec/GlecReport'));
const GHGReport = lazy(() => import('../pages/reports/emissions/ghg/GHGReport'));
const ItemisedEmissionsReport = lazy(
  () => import('../pages/reports/emissions/itemised_emissions/ItemisedEmissionsReport'),
);
const EmissionsReportDetail = lazy(() => import('../pages/reports/emissions/EmissionsReportDetail'));
const PortfolioDetailsPage = lazy(() => import('../pages/offsetting/PortfolioDetailsPage/PortfolioDetailsPage'));
const ProjectDetailsPage = lazy(() => import('../pages/offsetting/ProjectDetails/ProjectDetails'));
const Offsetting = lazy(() => import('../pages/offsetting/Offsetting'));
const OffsetLinks = lazy(() => import('../pages/offset-links/Offsetting'));
const OffsetLinkCreateEdit = lazy(() => import('../pages/offset-links/LinkCreateEdit/LinkCreateEdit'));
const OffsetLinkDetails = lazy(() => import('../pages/offset-links/LinkDetails/LinkDetails'));
const OrderOffsettingModal = lazy(() => import('../pages/offsetting/OrderOffsettingModal/OrderOffsettingModal'));
const PurchaseConfirmationModal = lazy(
  () => import('../pages/offsetting/PurchaseConfirmationModal/PurchaseConfirmationModal'),
);
const Orders = lazy(() => import('../pages/orders/Orders'));
const OrderDetails = lazy(() => import('../pages/orders/OrderDetails/OrderDetails'));
const ApiLogs = lazy(() => import('../pages/developers/ApiLogs/ApiLogs'));
const ApiLogDetails = lazy(() => import('../pages/developers/ApiLogs/LogDetails'));
const Developers = lazy(() => import('../pages/developers/Developers'));
const Profile = lazy(() => import('../pages/profile/Profile'));
const Organization = lazy(() => import('../pages/organization/Organization'));
const Team = lazy(() => import('../pages/team/TeamPage'));
const Billing = lazy(() => import('../pages/billing/Billing'));
const Calculator = lazy(() => import('../pages/calculator/CalculatorModal'));
const CalculatorSaved = lazy(() => import('../pages/calculator/CalculatorSaved'));
const Plans = lazy(() => import('../pages/plans/PlansPage'));
const TestData = lazy(() => import('../pages/test-data/ManageData'));
const Login = lazy(() => import('../pages/auth/login/SystemLogin'));
const ForgotPassword = lazy(() => import('../pages/auth/forgot-password/SystemForgotPassword'));
const ResetPassword = lazy(() => import('../pages/auth/reset-password/SystemResetPassword'));
const ConfirmMfa = lazy(() => import('../pages/auth/confirm-mfa/SystemConfirmMfa'));
const SignUp = lazy(() => import('../pages/auth/sign-up/SystemSignUp'));
const ConfirmSignUp = lazy(() => import('../pages/auth/confirm-sign-up/ConfirmSignUp'));
const ConfirmInvite = lazy(() => import('../pages/auth/confirm-invite/SystemConfirmInvite'));
const InviteRequired = lazy(() => import('../pages/auth/InviteRequired/SystemInviteRequired'));
const CreateProfile = lazy(() => import('../pages/auth/create-profile/SystemCreateProfile'));
const CreateOrganization = lazy(() => import('../pages/auth/create-organization/SystemCreateOrganization'));
const DiscoverCategory = lazy(() => import('../pages/discover/DiscoverCategory'));
const DiscoverFreightForwarderUseCase = lazy(() => import('../pages/discover/UseCase/DiscoverFreightForwarderUseCase'));
const DiscoverShipperUseCase = lazy(() => import('../pages/discover/UseCase/DiscoverShipperUseCase'));
const DiscoverCarrierUseCase = lazy(() => import('../pages/discover/UseCase/DiscoverCarrierUseCase'));
const DiscoverLspUseCase = lazy(() => import('../pages/discover/UseCase/DiscoverLspUseCase'));
const DiscoverOtherUseCase = lazy(() => import('../pages/discover/UseCase/DiscoverOtherUseCase'));
const DiscoverFreightForwarderFeature = lazy(() => import('../pages/discover/Feature/DiscoverFreightForwarderFeature'));
const DiscoverShipperFeature = lazy(() => import('../pages/discover/Feature/DiscoverShipperFeature'));
const DiscoverCarrierFeature = lazy(() => import('../pages/discover/Feature/DiscoverCarrierFeature'));
const DiscoverLspFeature = lazy(() => import('../pages/discover/Feature/DiscoverLspFeature'));
const DiscoverOtherFeature = lazy(() => import('../pages/discover/Feature/DiscoverOtherFeature'));
const Error404 = lazy(() => import('../pages/error/404/System404'));
const Error403 = lazy(() => import('../pages/error/403/System403'));
const Error500 = lazy(() => import('../pages/error/500/System500'));
const TrialExpired = lazy(() => import('../pages/error/trial-expired/SystemTrialExpired'));
const Integrations = lazy(() => import('../pages/integrations/Integrations'));
const ChainIO = lazy(() => import('../pages/integrations/pages/ChainIO/ChainIO'));
const EmailImport = lazy(() => import('../pages/integrations/pages/EmailImport'));
const FileExchange = lazy(() => import('../pages/integrations/pages/FileExchange'));
const CargoWise = lazy(() => import('../pages/integrations/pages/CargoWise/CargoWise'));
const CargoWiseProxy = lazy(() => import('../pages/integrations/pages/CargoWiseProxy/CargoWiseProxy'));
const IleapHost = lazy(() => import('../pages/integrations/pages/IleapHost/IleapHost'));
const InviteModal = lazy(() => import('../pages/team/modals/SendInvitation'));
const ImportModal = lazy(() => import('../pages/imports/new/ImportModal'));
const ChooseDataStage = lazy(() => import('../pages/imports/new/ChooseDataStage/ChooseDataStage'));
const UploadDataStage = lazy(() => import('../pages/imports/new/UploadDataStage/UploadDataStage'));
const CalculatingEmissionsStage = lazy(
  () => import('../pages/imports/new/CalculatingEmissionsStage/CalculatingEmissionsStage'),
);
const DoneStage = lazy(() => import('../pages/imports/new/DoneStage/DoneStage'));
const ImportDetailsPage = lazy(() => import('../pages/imports/ImportDetails'));
const DataCollectionOutboundPage = lazy(() => import('../pages/data-collection-outbound/DataCollectionOutbound'));
const DataCollectionOutboundCreateModal = lazy(
  () => import('../pages/data-collection-outbound/DataCollectionOutboundCreateModal'),
);
const DataCollectionOutboundDetails = lazy(
  () => import('../pages/data-collection-outbound/details/DataCollectionOutboundDetails'),
);
const DataCollectionOutboundDetailsRequest = lazy(
  () => import('../pages/data-collection-outbound/details/DataCollectionOutboundDetailsRequest'),
);
const DataCollectionOutboundDetailsRequestReview = lazy(
  () => import('../pages/data-collection-outbound/details/request/DataCollectionOutboundDetailsRequestReview'),
);
const DataCollectionOutboundDetailsRequestReviewCopy = lazy(
  () => import('../pages/data-collection-outbound/details/request/DataCollectionOutboundDetailsRequestReviewCopy'),
);
const DataCollectionInboundPage = lazy(() => import('../pages/data-collection-inbound/DataCollectionInbound'));
const DataCollectionInboundDetailsLazy = lazy(
  () => import('../pages/data-collection-inbound/details/DataCollectionInboundDetails'),
);

const SupplierDirectory = lazy(() => import('../pages/directory/SupplierDirectory'));
const CreateSupplierModal = lazy(() => import('../pages/directory/modals/CreateSupplierModal'));
const SupplierDirectoryEntry = lazy(() => import('../pages/directory/SupplierDirectoryEntry'));

const SupplierDirectoryEntryContactCreate = lazy(() => import('../pages/directory/SupplierDirectoryContactCreate'));
const SupplierDirectoryEntryContactEdit = lazy(() => import('../pages/directory/SupplierDirectoryContactEdit'));
const SupplierDirectoryEntryContactDelete = lazy(() => import('../pages/directory/SupplierDirectoryContactDelete'));
const SupplierDirectoryEntryEdit = lazy(() => import('../pages/directory/SupplierDirectoryEntryEdit'));
const SupplierDirectoryEntryMetadataEdit = lazy(() => import('../pages/directory/SupplierDirectoryEntryMetadataEdit'));

const ClientDirectory = lazy(() => import('../pages/directory/client/ClientDirectory'));
const CreateClientModal = lazy(() => import('../pages/directory/client/modals/CreateClientModal'));
const ClientDirectoryEntry = lazy(() => import('../pages/directory/client/ClientDirectoryEntry'));

const ClientDirectoryEntryContactCreate = lazy(() => import('../pages/directory/client/ClientDirectoryContactCreate'));
const ClientDirectoryEntryContactEdit = lazy(() => import('../pages/directory/client/ClientDirectoryContactEdit'));
const ClientDirectoryEntryContactDelete = lazy(() => import('../pages/directory/client/ClientDirectoryContactDelete'));

const ClientDirectoryEntryEdit = lazy(() => import('../pages/directory/client/ClientDirectoryEntryEdit'));
const ClientDirectoryEntryMetadataEdit = lazy(
  () => import('../pages/directory/client/ClientDirectoryEntryMetadataEdit'),
);

const sentryCreateBrowserRouter =
  process.env.NODE_ENV === 'production' ? Sentry.wrapCreateBrowserRouter(createBrowserRouter) : createBrowserRouter;

export const router = sentryCreateBrowserRouter(
  [
    // redirects
    {
      index: true,
      element: <Navigate to="/dashboard" replace={true} />,
    },
    {
      path: 'test',
      element: <Navigate to="/test/dashboard" replace={true} />,
    },

    // App
    {
      path: '/',
      element: (
        <RACRouterProvider>
          <Suspense fallback={null}>
            <RequireFeatureFlags>
              <RootLayout />
            </RequireFeatureFlags>
          </Suspense>
        </RACRouterProvider>
      ),
      ErrorBoundary: RouterErrorBoundaryFallback500Redirect,
      children: [
        // authenticated app pages
        {
          path: 'test?',
          element: (
            <RequireUserLoaded>
              <RequireAuthenticated>
                <RequireCompletedSignUp>
                  <RequireActiveSubscription>
                    <Outlet />
                  </RequireActiveSubscription>
                </RequireCompletedSignUp>
              </RequireAuthenticated>
            </RequireUserLoaded>
          ),
          children: [
            // test mode sensitive (renders a test mode header bar)
            {
              element: <MainLayout testModeSensitive={true} />,
              children: [
                {
                  path: 'dashboard',
                  element: <Home />,
                },
                {
                  path: 'dashboards',
                  element: (
                    <FeatureFlag name="analytics-page" fallback={<Navigate to="/404" replace={true} />}>
                      <ACL
                        fallback={<Navigate to="/403" replace={true} />}
                        roles={[
                          ClientRoleEnum.Admin,
                          ClientRoleEnum.Owner,
                          ClientRoleEnum.Developer,
                          ClientRoleEnum.Viewer,
                          ClientRoleEnum.Operations,
                        ]}
                      >
                        <Outlet />
                      </ACL>
                    </FeatureFlag>
                  ),
                  children: [
                    {
                      path: 'emissions',
                      children: [
                        {
                          path: 'overview',
                          element: (
                            <FeatureFlag
                              name="analytics-emissions-overview"
                              fallback={<Navigate to="/404" replace={true} />}
                            >
                              <DashboardEmissionsOverview />
                            </FeatureFlag>
                          ),
                        },
                        {
                          path: 'client',
                          element: (
                            <Entitlement
                              isEntitled={hasEntitlement(PLATFORM_CLIENTS_ENTITLEMENT)}
                              fallback={<Navigate to="/404" replace={true} />}
                            >
                              <FeatureFlag
                                name="analytics-emissions-client"
                                fallback={<Navigate to="/404" replace={true} />}
                              >
                                <DashboardEmissionsClient />
                              </FeatureFlag>
                            </Entitlement>
                          ),
                        },
                        {
                          path: 'supplier',
                          element: (
                            <Entitlement
                              isEntitled={hasEntitlement(PLATFORM_SUPPLIERS_ENTITLEMENT)}
                              fallback={<Navigate to="/404" replace={true} />}
                            >
                              <FeatureFlag
                                name="analytics-emissions-supplier"
                                fallback={<Navigate to="/404" replace={true} />}
                              >
                                <DashboardEmissionsSupplier />
                              </FeatureFlag>
                            </Entitlement>
                          ),
                        },
                        {
                          path: 'intensity',
                          element: (
                            <FeatureFlag
                              name="analytics-emissions-intensity"
                              fallback={<Navigate to="/404" replace={true} />}
                            >
                              <DashboardEmissionsIntensity />
                            </FeatureFlag>
                          ),
                        },
                        {
                          path: 'accuracy',
                          element: (
                            <FeatureFlag
                              name="analytics-emissions-accuracy"
                              fallback={<Navigate to="/404" replace={true} />}
                            >
                              <DashboardEmissionsAccuracy />
                            </FeatureFlag>
                          ),
                        },
                        {
                          path: 'north-america-road-freight',
                          element: (
                            <FeatureFlag
                              name="analytics-shipments-north-america-road"
                              fallback={<Navigate to="/404" replace={true} />}
                            >
                              <DashboardEmissionsNorthAmericaRoadFreight />
                            </FeatureFlag>
                          ),
                        },
                        {
                          path: 'sea-freight',
                          element: (
                            <FeatureFlag
                              name="analytics-emissions-sea-freight"
                              fallback={<Navigate to="/404" replace={true} />}
                            >
                              <DashboardEmissionsSeaFreight />
                            </FeatureFlag>
                          ),
                        },
                        {
                          path: 'air-freight',
                          element: (
                            <FeatureFlag
                              name="analytics-emissions-air-freight"
                              fallback={<Navigate to="/404" replace={true} />}
                            >
                              <DashboardEmissionsAirFreight />
                            </FeatureFlag>
                          ),
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'data-collections/outbound',
                  element: (
                    <FeatureFlag name="data-collection-outbound-page" fallback={<Navigate to="/404" replace={true} />}>
                      <ACL
                        fallback={<Navigate to="/403" replace={true} />}
                        roles={[
                          ClientRoleEnum.Admin,
                          ClientRoleEnum.Owner,
                          ClientRoleEnum.Developer,
                          ClientRoleEnum.Viewer,
                          ClientRoleEnum.Operations,
                        ]}
                      >
                        <DataCollectionOutboundPage />
                      </ACL>
                    </FeatureFlag>
                  ),
                  children: [
                    {
                      path: 'new',
                      element: (
                        <ACL
                          fallback={<Navigate to="/403" replace={true} />}
                          roles={[
                            ClientRoleEnum.Admin,
                            ClientRoleEnum.Owner,
                            ClientRoleEnum.Developer,
                            ClientRoleEnum.Operations,
                          ]}
                        >
                          <DataCollectionOutboundCreateModal />
                        </ACL>
                      ),
                    },
                    {
                      path: ':collectionId/requests/:requestId/review', // this must be a separate route to avoid conflicts between modals
                      element: <DataCollectionOutboundDetailsRequestReview />,
                    },
                    {
                      path: ':collectionId/requests/:requestId/review/copy', // this must be a separate route to avoid conflicts between modals
                      element: <DataCollectionOutboundDetailsRequestReviewCopy />,
                    },
                    {
                      path: ':collectionId',
                      element: <DataCollectionOutboundDetails />,
                      children: [
                        {
                          path: 'requests/:requestId',
                          element: <DataCollectionOutboundDetailsRequest />,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'data-collections/inbound',
                  element: (
                    <FeatureFlag name="data-collection-inbound-page" fallback={<Navigate to="/404" replace={true} />}>
                      <ACL
                        fallback={<Navigate to="/403" replace={true} />}
                        roles={[
                          ClientRoleEnum.Admin,
                          ClientRoleEnum.Owner,
                          ClientRoleEnum.Developer,
                          ClientRoleEnum.Viewer,
                          ClientRoleEnum.Operations,
                        ]}
                      >
                        <DataCollectionInboundPage />
                      </ACL>
                    </FeatureFlag>
                  ),
                  children: [
                    {
                      path: ':requestId',
                      element: <DataCollectionInboundDetailsLazy />,
                    },
                  ],
                },
                {
                  path: 'imports',
                  element: (
                    <ACL
                      fallback={<Navigate to="/403" replace={true} />}
                      roles={[
                        ClientRoleEnum.Admin,
                        ClientRoleEnum.Owner,
                        ClientRoleEnum.Developer,
                        ClientRoleEnum.Viewer,
                        ClientRoleEnum.Operations,
                      ]}
                    >
                      <Imports />
                    </ACL>
                  ),
                  children: [
                    {
                      path: ':importId',
                      element: <ImportDetailsPage />,
                    },
                    {
                      path: 'new',
                      element: <ImportModal />,
                      children: [
                        {
                          index: true,
                          element: <ChooseDataStage />,
                        },
                        {
                          path: ':vertical',
                          element: <UploadDataStage />,
                        },
                        {
                          path: ':vertical/:id',
                          element: <CalculatingEmissionsStage />,
                        },
                        {
                          path: ':vertical/:id/done',
                          element: <DoneStage />,
                        },
                      ],
                    },
                  ],
                },
                // Quick view emissions
                {
                  path: 'emissions',
                  element: <Emissions />,
                  children: [
                    {
                      path: 'q/:emissionId',
                      element: <EmissionDetailsDrawer />,
                      children: [
                        {
                          path: ':childEmissionId/:view?',
                          element: <EmissionChildDetails />,
                        },
                      ],
                    },
                  ],
                },
                // Full page emissions
                {
                  path: 'emissions',
                  element: <Outlet />,
                  handle: {
                    crumb: () => ({
                      title: <FormattedMessage id="emissions.title" />,
                      content: <FormattedMessage id="emissions.title" />,
                    }),
                  },
                  children: [
                    {
                      path: ':emissionId',
                      element: <EmissionDetailsLayout />,
                      handle: {
                        crumb: ({ params }: UIMatch) => ({
                          title: params.emissionId,
                          content: params.emissionId,
                        }),
                      },
                      children: [
                        {
                          index: true,
                          element: <EmissionDetails />,
                        },
                        {
                          path: ':childEmissionId',
                          handle: {
                            crumb: ({ params }: UIMatch) => ({
                              title: params.childEmissionId,
                              content: params.childEmissionId,
                            }),
                          },
                          children: [
                            {
                              index: true,
                              element: <EmissionDetails />,
                            },
                            {
                              path: 'accuracy',
                              element: <EmissionDetailsAccuracyView />,
                              handle: {
                                crumb: () => ({
                                  title: <FormattedMessage id="emissions.details.header.accuracy" />,
                                  content: <FormattedMessage id="emissions.details.header.accuracy" />,
                                }),
                              },
                            },
                            {
                              path: 'breakdown',
                              element: <EmissionDetailsBreakdownView />,
                              handle: {
                                crumb: () => ({
                                  title: <FormattedMessage id="emissions.details.header.breakdown" />,
                                  content: <FormattedMessage id="emissions.details.header.breakdown" />,
                                }),
                              },
                            },
                            {
                              path: 'clarity',
                              element: <EmissionDetailsClarityView />,
                              handle: {
                                crumb: () => ({
                                  title: <FormattedMessage id="emissions.details.header.clarity" />,
                                  content: <FormattedMessage id="emissions.details.header.clarity" />,
                                }),
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  // todo: CLI-3281 split the results panel into a separate route
                  path: 'calculators/freight/:emissionId?/:childEmissionId?/:view?',
                  element: <Calculator />,
                },
                {
                  path: 'calculators/freight/saved/:publicId',
                  element: <CalculatorSaved />,
                },
                {
                  path: 'reports/emissions',
                  element: (
                    <ACL
                      fallback={<Navigate to="/403" replace={true} />}
                      roles={[
                        ClientRoleEnum.Admin,
                        ClientRoleEnum.Owner,
                        ClientRoleEnum.Developer,
                        ClientRoleEnum.Viewer,
                        ClientRoleEnum.Operations,
                      ]}
                    >
                      <ReportsEmissions />
                    </ACL>
                  ),
                  children: [
                    {
                      path: ':reportType/:publicId/:modalType?',
                      element: <EmissionsReportDetail />,
                    },
                    {
                      path: 'new',
                      element: <CreateEmissionsReport />,
                      children: [
                        {
                          index: true,
                          element: <ChooseReport />,
                        },
                        {
                          path: 'glec',
                          element: <GlecReport />,
                        },
                        {
                          path: 'ghg',
                          element: <GHGReport />,
                        },
                        {
                          path: 'itemised-emissions',
                          element: <ItemisedEmissionsReport />,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'offsetting',
                  handle: {
                    crumb: () => ({
                      content: <FormattedMessage id="leftMenu.offsetting" />,
                      title: 'Offsetting',
                    }),
                  },
                  children: [
                    {
                      index: true,
                      element: (
                        <ACL
                          fallback={<Navigate to="/403" replace={true} />}
                          roles={[ClientRoleEnum.Admin, ClientRoleEnum.Owner, ClientRoleEnum.Operations]}
                        >
                          <Offsetting />
                        </ACL>
                      ),
                    },
                    {
                      path: 'portfolios/:portfolioId',
                      element: (
                        <ACL
                          fallback={<Navigate to="/403" replace={true} />}
                          roles={[ClientRoleEnum.Admin, ClientRoleEnum.Owner, ClientRoleEnum.Operations]}
                        >
                          <PortfolioDetailsPage />
                        </ACL>
                      ),
                      handle: {
                        crumb: ({ params }: UIMatch) => ({
                          content: (
                            <FormattedMessage id="offsetting.portfolio.crumb" values={{ id: params.portfolioId }} />
                          ),
                          title: (
                            <FormattedMessage id="offsetting.portfolio.crumb" values={{ id: params.portfolioId }} />
                          ),
                        }),
                      },
                      children: [
                        {
                          path: 'projects/:projectId',
                          element: <ProjectDetailsPage />,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'impact-links',
                  element: (
                    <ACL
                      fallback={<Navigate to="/403" replace={true} />}
                      roles={[ClientRoleEnum.Admin, ClientRoleEnum.Owner, ClientRoleEnum.Operations]}
                    >
                      <OffsetLinks />
                    </ACL>
                  ),
                  children: [
                    {
                      path: 'new',
                      element: <OffsetLinkCreateEdit />,
                    },
                    {
                      path: ':linkId/edit',
                      element: <OffsetLinkCreateEdit />,
                    },
                    {
                      path: ':linkId',
                      element: <OffsetLinkDetails />,
                    },
                  ],
                },
                {
                  path: 'orders/new',
                  element: (
                    <ACL
                      fallback={<Navigate to="/403" replace={true} />}
                      roles={[ClientRoleEnum.Admin, ClientRoleEnum.Owner, ClientRoleEnum.Operations]}
                    >
                      <OrderOffsettingModal />
                    </ACL>
                  ),
                },
                {
                  path: 'orders/complete',
                  element: (
                    <ACL
                      fallback={<Navigate to="/403" replace={true} />}
                      roles={[ClientRoleEnum.Admin, ClientRoleEnum.Owner, ClientRoleEnum.Operations]}
                    >
                      <OrderOffsettingModal />
                    </ACL>
                  ),
                },
                {
                  path: 'orders/confirmation',
                  element: (
                    <ACL
                      fallback={<Navigate to="/403" replace={true} />}
                      roles={[ClientRoleEnum.Admin, ClientRoleEnum.Owner, ClientRoleEnum.Operations]}
                    >
                      <PurchaseConfirmationModal />
                    </ACL>
                  ),
                },
                {
                  path: 'orders',
                  element: (
                    <ACL
                      fallback={<Navigate to="/403" replace={true} />}
                      roles={[ClientRoleEnum.Admin, ClientRoleEnum.Owner, ClientRoleEnum.Operations]}
                    >
                      <Orders />
                    </ACL>
                  ),
                  children: [
                    {
                      path: ':orderId',
                      element: <OrderDetails />,
                    },
                  ],
                },
                {
                  path: 'developers',
                  handle: {
                    crumb: () => ({
                      content: <FormattedMessage id="developer.title" />,
                      title: `Developper`,
                    }),
                  },
                  element: (
                    <ACL
                      fallback={<Navigate to="/403" replace={true} />}
                      roles={[ClientRoleEnum.Owner, ClientRoleEnum.Admin, ClientRoleEnum.Developer]}
                    >
                      <LiveModePrompt key="developers">
                        <Outlet />
                      </LiveModePrompt>
                    </ACL>
                  ),
                  children: [
                    {
                      index: true,
                      element: <Developers />,
                    },
                    {
                      path: 'logs',
                      element: <ApiLogs />,
                      handle: {
                        crumb: () => ({
                          content: <FormattedMessage id="developer.api_logs.title" />,
                          title: `API logs`,
                        }),
                      },
                      children: [
                        {
                          path: ':logId',
                          element: <ApiLogDetails />,
                          handle: {
                            crumb: () => ({
                              content: <FormattedMessage id="developer.api_logs.details" />,
                              title: `API log`,
                            }),
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'test-data',
                  element: (
                    <ACL
                      fallback={<Navigate to="/403" replace={true} />}
                      roles={[ClientRoleEnum.Admin, ClientRoleEnum.Owner, ClientRoleEnum.Operations]}
                    >
                      <TestData />
                    </ACL>
                  ),
                },
                {
                  path: 'integrations',
                  handle: {
                    crumb: () => ({
                      content: <FormattedMessage id="integrations.title" />,
                      title: `Integrations`,
                    }),
                  },
                  element: (
                    <ACL
                      fallback={<Navigate to="/403" replace={true} />}
                      roles={[
                        ClientRoleEnum.Owner,
                        ClientRoleEnum.Admin,
                        ClientRoleEnum.Operations,
                        ClientRoleEnum.Developer,
                      ]}
                    >
                      <LiveModePrompt key="integrations">
                        <Outlet />
                      </LiveModePrompt>
                    </ACL>
                  ),
                  children: [
                    {
                      index: true,
                      element: <Integrations />,
                    },
                    {
                      path: 'email-import/:publicId?',
                      element: <EmailImport />,
                      handle: {
                        crumb: () => ({
                          content: <FormattedMessage id="integrations.page.title.email-import" />,
                          title: `Email Import`,
                        }),
                      },
                    },
                    {
                      path: 'file-exchange/:publicId?',
                      element: <FileExchange />,
                      handle: {
                        crumb: () => ({
                          content: <FormattedMessage id="integrations.page.title.file-exchange" />,
                          title: `File Exchange`,
                        }),
                      },
                    },
                    {
                      path: 'cargowise/:publicId?',
                      element: <CargoWise />,
                      handle: {
                        crumb: () => ({
                          content: <FormattedMessage id="integrations.page.title.cargowise" />,
                          title: 'Cargowise',
                        }),
                      },
                    },
                    {
                      path: 'cargowise-proxy/:publicId?',
                      element: <CargoWiseProxy />,
                      handle: {
                        crumb: () => ({
                          content: <FormattedMessage id="integrations.page.title.cargowise-proxy" />,
                          title: 'Cargowise Proxy',
                        }),
                      },
                    },
                    {
                      path: 'chainio/:publicId?',
                      element: (
                        <FeatureFlag name="integrations-chainio" fallback={<Navigate to="/404" replace={true} />}>
                          <ChainIO />
                        </FeatureFlag>
                      ),
                      handle: {
                        crumb: () => ({
                          content: <FormattedMessage id="integrations.page.title.chainio" />,
                          title: 'Chain IO',
                        }),
                      },
                    },
                    {
                      path: 'ileap-host/:publicId?',
                      element: (
                        <FeatureFlag name="integrations-ileap-host" fallback={<Navigate to="/404" replace={true} />}>
                          <IleapHost />
                        </FeatureFlag>
                      ),
                      handle: {
                        crumb: () => ({
                          content: <FormattedMessage id="integrations.page.title.ileap-host" />,
                          title: 'iLEAP host',
                        }),
                      },
                    },
                  ],
                },
              ],
            },

            // test mode insensitive
            {
              element: <MainLayout testModeSensitive={false} />,
              children: [
                {
                  path: 'profile',
                  element: <Profile />,
                },
                {
                  path: 'organization',
                  element: (
                    <ACL
                      fallback={<Navigate to="/403" replace={true} />}
                      roles={[ClientRoleEnum.Admin, ClientRoleEnum.Owner]}
                    >
                      <Organization />
                    </ACL>
                  ),
                },
                {
                  path: 'sharing',
                  element: (
                    <ACL
                      fallback={<Navigate to="/403" replace={true} />}
                      roles={[ClientRoleEnum.Admin, ClientRoleEnum.Owner]}
                    >
                      <Organization />
                    </ACL>
                  ),
                },
                {
                  path: 'branding',
                  element: (
                    <ACL
                      fallback={<Navigate to="/403" replace={true} />}
                      roles={[ClientRoleEnum.Admin, ClientRoleEnum.Owner]}
                    >
                      <Organization />
                    </ACL>
                  ),
                },
                {
                  path: 'calculation',
                  element: (
                    <FeatureFlag
                      name="organisation-settings-calculation"
                      fallback={<Navigate to="/404" replace={true} />}
                    >
                      <ACL
                        fallback={<Navigate to="/403" replace={true} />}
                        roles={[ClientRoleEnum.Admin, ClientRoleEnum.Owner]}
                      >
                        <Organization />
                      </ACL>
                    </FeatureFlag>
                  ),
                },
                {
                  path: 'team',
                  element: (
                    <ACL
                      fallback={<Navigate to="/403" replace={true} />}
                      roles={[ClientRoleEnum.Admin, ClientRoleEnum.Owner]}
                    >
                      <Team />
                    </ACL>
                  ),
                  children: [{ path: 'invite', element: <InviteModal /> }],
                },
                {
                  path: 'billing',
                  element: (
                    <ACL
                      fallback={<Navigate to="/403" replace={true} />}
                      roles={[ClientRoleEnum.Admin, ClientRoleEnum.Owner]}
                    >
                      <Billing />
                    </ACL>
                  ),
                },
                {
                  path: 'plans',
                  element: <Plans />,
                },
                {
                  path: 'directory/supplier',
                  handle: {
                    crumb: () => ({
                      content: <FormattedMessage id="directory.supplier.breadcrumb.directory.content" />,
                      title: <FormattedMessage id="directory.supplier.breadcrumb.directory.title" />,
                    }),
                  },
                  element: (
                    <ACL
                      fallback={<Navigate to="/403" replace={true} />}
                      roles={[ClientRoleEnum.Admin, ClientRoleEnum.Operations, ClientRoleEnum.Owner]}
                    >
                      <Entitlement
                        isEntitled={hasEntitlement(PLATFORM_DIRECTORY_SUPPLIER_ENTITLEMENT)}
                        fallback={<Navigate to="/404" replace={true} />}
                      >
                        <Outlet />
                      </Entitlement>
                    </ACL>
                  ),
                  children: [
                    {
                      index: true,
                      element: <SupplierDirectory />,
                    },
                    {
                      path: 'new',
                      element: (
                        <>
                          <SupplierDirectory />
                          <CreateSupplierModal />
                        </>
                      ),
                    },
                    {
                      path: ':directoryEntryId',
                      handle: {
                        crumb: () => ({
                          content: <FormattedMessage id="directory.supplier.breadcrumb.entry.content" />,
                          title: <FormattedMessage id="directory.supplier.breadcrumb.entry.title" />,
                        }),
                      },
                      element: <SupplierDirectoryEntry />,
                      children: [
                        {
                          path: 'contact/add',
                          element: <SupplierDirectoryEntryContactCreate />,
                        },
                        {
                          path: 'contact/:contactId/edit',
                          element: <SupplierDirectoryEntryContactEdit />,
                        },
                        {
                          path: 'contact/:contactId/delete',
                          element: <SupplierDirectoryEntryContactDelete />,
                        },
                        {
                          path: 'edit',
                          element: <SupplierDirectoryEntryEdit />,
                        },
                        {
                          path: 'edit/metadata',
                          element: <SupplierDirectoryEntryMetadataEdit />,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'directory/client',
                  handle: {
                    crumb: () => ({
                      content: <FormattedMessage id="directory.client.breadcrumb.directory.content" />,
                      title: <FormattedMessage id="directory.client.breadcrumb.directory.title" />,
                    }),
                  },
                  element: (
                    <ACL
                      fallback={<Navigate to="/403" replace={true} />}
                      roles={[ClientRoleEnum.Admin, ClientRoleEnum.Operations, ClientRoleEnum.Owner]}
                    >
                      <Entitlement
                        isEntitled={hasEntitlement(PLATFORM_DIRECTORY_CLIENT_ENTITLEMENT)}
                        fallback={<Navigate to="/404" replace={true} />}
                      >
                        <Outlet />
                      </Entitlement>
                    </ACL>
                  ),
                  children: [
                    {
                      index: true,
                      element: <ClientDirectory />,
                    },
                    {
                      path: 'new',
                      element: (
                        <>
                          <ClientDirectory />
                          <CreateClientModal />
                        </>
                      ),
                    },
                    {
                      path: ':directoryEntryId',
                      handle: {
                        crumb: () => ({
                          content: <FormattedMessage id="directory.client.breadcrumb.entry.content" />,
                          title: <FormattedMessage id="directory.client.breadcrumb.entry.title" />,
                        }),
                      },
                      element: <ClientDirectoryEntry />,
                      children: [
                        {
                          path: 'edit',
                          element: <ClientDirectoryEntryEdit />,
                        },
                        {
                          path: 'edit/metadata',
                          element: <ClientDirectoryEntryMetadataEdit />,
                        },
                        {
                          path: 'contact/add',
                          element: <ClientDirectoryEntryContactCreate />,
                        },
                        {
                          path: 'contact/:contactId/edit',
                          element: <ClientDirectoryEntryContactEdit />,
                        },
                        {
                          path: 'contact/:contactId/delete',
                          element: <ClientDirectoryEntryContactDelete />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },

        // Discover pages
        {
          path: 'test?/discover',
          element: (
            <RequireUserLoaded>
              <RequireAuthenticated>
                <RequireCompletedSignUp>
                  <ACL
                    fallback={<Navigate to="/403" replace={true} />}
                    roles={[
                      ClientRoleEnum.Admin,
                      ClientRoleEnum.Owner,
                      ClientRoleEnum.Developer,
                      ClientRoleEnum.Operations,
                    ]}
                  >
                    <DiscoverLayout />
                  </ACL>
                </RequireCompletedSignUp>
              </RequireAuthenticated>
            </RequireUserLoaded>
          ),
          children: [
            /*
            Discover Category page
          */
            {
              path: ':functionType/categories/:category?',
              element: <DiscoverCategory />,
            },
            /*
            Discover Use Case pages
          */
            {
              path: 'freight_forwarder/categories/:category/use-cases/:useCase?',
              element: <DiscoverFreightForwarderUseCase />,
            },
            {
              path: 'shipper/categories/:category/use-cases/:useCase?',
              element: <DiscoverShipperUseCase />,
            },
            {
              path: 'carrier/categories/:category/use-cases/:useCase?',
              element: <DiscoverCarrierUseCase />,
            },
            {
              path: 'lsp/categories/:category/use-cases/:useCase?',
              element: <DiscoverLspUseCase />,
            },
            {
              path: 'other/categories/:category/use-cases/:useCase?',
              element: <DiscoverOtherUseCase />,
            },
            /*
            Discover Feature pages
          */
            {
              path: 'freight_forwarder/categories/:category/use-cases/:useCase/features',
              element: <DiscoverFreightForwarderFeature />,
            },
            {
              path: 'shipper/categories/:category/use-cases/:useCase/features',
              element: <DiscoverShipperFeature />,
            },
            {
              path: 'carrier/categories/:category/use-cases/:useCase/features',
              element: <DiscoverCarrierFeature />,
            },
            {
              path: 'lsp/categories/:category/use-cases/:useCase/features',
              element: <DiscoverLspFeature />,
            },
            {
              path: 'other/categories/:category/use-cases/:useCase/features',
              element: <DiscoverOtherFeature />,
            },
          ],
        },

        // SUSI pages
        {
          path: 'auth',
          element: (
            <RequireCompletedSignUp>
              <AuthLayout />
            </RequireCompletedSignUp>
          ),
          children: [
            {
              path: 'sign-in',
              element: <Login />,
            },
            {
              path: 'forgot-password',
              element: <ForgotPassword />,
            },
            {
              path: 'reset-password',
              element: <ResetPassword />,
            },
            {
              path: 'confirm-mfa',
              element: <ConfirmMfa />,
            },
            {
              path: 'sign-up',
              element: <SignUp />,
            },
            {
              path: 'confirm-sign-up',
              element: <ConfirmSignUp />,
            },
            {
              path: 'confirm-invite',
              element: <ConfirmInvite />,
            },
            {
              path: 'request-send',
              element: <InviteRequired />,
            },
            {
              path: 'create-profile',
              element: <CreateProfile />,
            },
            {
              path: 'create-organization',
              element: <CreateOrganization />,
            },
          ],
        },

        // error pages
        {
          element: <OneColumnLayout />,
          children: [
            {
              path: '404',
              element: <Error404 />,
            },
            {
              path: '403',
              element: <Error403 />,
            },
            {
              path: '500',
              element: <Error500 />,
            },
            {
              path: 'trial-expired',
              element: <TrialExpired />,
            },
          ],
        },

        // download pages
        {
          element: (
            <RequireUserLoaded>
              <RequireAuthenticated>
                <RequireCompletedSignUp>
                  <ACL
                    fallback={<Navigate to="/403" replace={true} />}
                    roles={[ClientRoleEnum.Admin, ClientRoleEnum.Owner, ClientRoleEnum.Operations]}
                  >
                    <OneColumnLayout />
                  </ACL>
                </RequireCompletedSignUp>
              </RequireAuthenticated>
            </RequireUserLoaded>
          ),
          children: [
            {
              path: 'emissions/download/:publicEmissionId',
              element: <EmissionsExportDownload />,
            },
          ],
        },
      ],
    },

    // 404
    {
      path: '*',
      element: <RedirectTo404 />,
    },
  ],
  {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  },
);

export function Router() {
  return (
    <RouterProvider
      router={router}
      fallbackElement={null}
      future={{
        v7_startTransition: true,
      }}
    />
  );
}
