import store from 'store';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface SettingsState {
  theme: 'light' | 'dark';
  locale: string;
}

const { actions, reducer } = createSlice({
  name: 'settings',
  initialState: (): SettingsState => ({
    theme: store.get(`app.settings.theme`) === 'dark' ? 'dark' : 'light',
    locale: 'en-GB',
  }),
  reducers: {
    themeChanged(state, action: PayloadAction<{ theme: SettingsState['theme'] }>) {
      return { ...state, theme: action.payload.theme };
    },
    localeChanged(state, action: PayloadAction<{ language: string | null | undefined }>) {
      if (!action.payload.language) {
        return state;
      }

      return { ...state, locale: action.payload.language };
    },
  },
});

export const { themeChanged, localeChanged } = actions;

export { reducer };
